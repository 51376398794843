import React, { useState, useEffect } from "react";
import Layout from "../layout";
import SEO from "../components/seo";
import { graphql, Link } from "gatsby";
import styled from "styled-components";
import ReactPlayer from "react-player";
import { GatsbyImage } from "gatsby-plugin-image";
import {
  gotham,
  gothamBold,
  aku,
  mainWhite,
  mainRed,
  transHover,
  innerWidth,
  navyBlue,
  mainBlue,
  lightBlue,
  screen,
} from "../components/common/variables";
import { moveBgColor } from "../components/common/animations";
import { IoIosPlay } from "react-icons/io";
import VideoModal from "../components/video-modal";
import Arrow from "../images/svg/right-arrow.svg";
import { Link as Spy } from "react-scroll";
import {
  getVimeoId,
  reduceWords,
  toSlug,
} from "../components/common/functions";
import Slider from "react-slick";
import logo from "../images/tenalphas-moving-content.jpg";
import NewsletterPopup from "../components/newsletter-popup/auto";

const Wrapper = styled.div`
  padding-top: 80px;

  .hero-container {
    position: relative;
    overflow: hidden;
    background: #000;
    @media ${screen.small} {
      height: 740px;
    }
    @media ${screen.medium} {
      height: 620px;
    }
    @media ${screen.large} {
      height: 750px;
    }
    @media ${screen.miniLarge} {
      height: 650px;
    }
    @media ${screen.xLarge} {
      height: 1150px;
    }

    &__video {
      display: none;
      @media ${screen.small} {
        display: block;
        position: absolute;
        top: 0;
        left: 50%;
        pointer-events: none;
        max-width: 1920px !important;
        width: 100% !important;
        height: 100% !important;
        transform: scale(1.8) translateX(-50%);
        transform-origin: 0 50%;
      }
      @media ${screen.medium} {
        transform: scale(1.2) translateX(-50%);
      }
      @media ${screen.large} {
        transform: scale(1.2) translateX(-50%);
        padding-top: 95px;
      }
    }

    &__image {
      height: 520px;

      .thumbnail {
        height: 100% !important;
        pointer-events: none;
        .react-player__play-icon,
        .react-player__shadow {
          display: none !important;
        }
      }
      @media ${screen.small} {
        display: none;
      }
    }

    &__overlay {
      background-color: rgba(0, 0, 0, 0.45);
      position: absolute;
      z-index: 2;
      width: 100%;
      height: 100%;
      top: 0;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      padding-bottom: 30px;
      padding-left: 25px;
      padding-right: 25px;
      @media ${screen.small} {
        display: block;
        padding-bottom: 0;
        padding-left: 0;
        padding-right: 0;
      }

      .texts {
        @media ${screen.small} {
          position: absolute;
          max-width: ${innerWidth};
          width: 100%;
          left: 50%;
          transform: translateX(-50%);
          bottom: 90px;
          padding-left: 40px;
          padding-right: 40px;
        }
        @media ${screen.medium} {
          padding-left: 50px;
          padding-right: 50px;
        }
        @media ${screen.xLarge} {
          padding-left: 0;
          padding-right: 0;
        }

        &__site-title {
          color: ${mainWhite};
          font-size: 2rem;
          font-family: ${gotham};
          @media ${screen.small} {
            font-size: 2.9rem;
          }
          @media ${screen.medium} {
            font-size: 2.9rem;
          }
          @media ${screen.large} {
            font-size: 4.4rem;
          }
          @media ${screen.xLarge} {
            font-size: 5rem;
          }

          &--subtitle {
            display: block;
            font-size: 1rem;
            margin-top: 5px;
            line-height: 1.25;
            white-space: pre-line;
            @media ${screen.small} {
              font-size: 1.5rem;
              margin-top: 10px;
            }
            @media ${screen.medium} {
              font-size: 1.6rem;
            }
            @media ${screen.large} {
              font-size: 1.8rem;
            }
            @media ${screen.xLarge} {
              font-size: 2rem;
            }
          }
        }
      }

      .video-title {
        font-family: ${aku};
        color: ${mainWhite};
        max-width: 94px;
        border-bottom: 2px ${mainWhite} solid;
        padding-bottom: 10px;
        margin-top: 5px;
        font-size: 1.2rem;
        @media ${screen.small} {
          max-width: 300px;
          position: absolute;
          border-bottom: 2px ${mainWhite} solid;
          bottom: 90px;
          right: 130px;
          font-size: 1.5rem;
          font-weight: 100;
          margin-top: 0;
        }
        @media ${screen.medium} {
          font-size: 1.4rem;
        }
        @media ${screen.large} {
          font-size: 1.5rem;
        }

        &--year {
          display: block;
        }
      }
    }

    &__play-icon {
      color: ${mainWhite};
      position: absolute;
      font-size: 3.9rem;
      top: 50%;
      left: 50%;
      z-index: 7;
      transform: translate(-50%, -50%);
      transition: ${transHover};
      @media ${screen.small} {
        font-size: 4rem;
        top: 46%;
      }
      @media ${screen.large} {
        font-size: 6rem;
        top: 50%;
      }
      @media ${screen.xLarge} {
        font-size: 8rem;
      }
      &:hover {
        color: ${mainRed};
      }

      svg {
        cursor: pointer;
      }
    }
  }

  .services-container {
    max-width: ${innerWidth};
    margin: 0 auto;
    padding-top: 50px;
    padding-left: 25px;
    padding-right: 25px;
    @media ${screen.small} {
      padding-left: 40px;
      padding-right: 40px;
      padding-top: 90px;
    }
    @media ${screen.medium} {
      padding-left: 50px;
      padding-right: 50px;
    }
    @media ${screen.xLarge} {
      padding-left: 0;
      padding-right: 0;
    }

    &__heading {
      color: ${navyBlue};
      font-family: ${gotham};
      font-size: 1.3rem;
      vertical-align: middle;
      @media ${screen.small} {
        font-size: 2rem;
      }
      @media ${screen.medium} {
        font-size: 2.7rem;
      }

      a {
        pointer-events: none;
      }

      svg {
        width: 14px;
        margin-right: 8px;
        @media ${screen.small} {
          width: 19px;
          margin-right: 14px;
        }
        @media ${screen.medium} {
          width: 24px;
        }

        .body {
          fill: ${navyBlue};
          transition: ${transHover};
        }
      }

      .active {
        .body {
          fill: ${mainRed};
        }
      }
    }

    &__service-lists {
      display: flex;
      margin-top: 7px;
      flex-wrap: wrap;
      @media ${screen.small} {
        margin-top: 14px;
        flex-wrap: nowrap;
      }

      .list {
        max-width: 370px;
        width: 50%;
        margin-top: 20px;
        padding-right: 15px;
        @media ${screen.small} {
          width: 100%;
          margin-top: 26px;
        }

        li {
          a {
            color: ${navyBlue};
            display: table;
            font-family: ${gotham};
            font-size: 1.1rem;
            transition: ${transHover};
            margin-bottom: 20px;
            @media ${screen.small} {
              font-size: 1.42rem;
              margin-bottom: 26px;
            }
            &:hover {
              color: ${mainRed};
            }
          }
        }

        &:nth-child(3) {
          margin-top: 0;
          @media ${screen.small} {
            margin-top: 26px;
          }
        }
      }
    }
  }

  .latest-work-container {
    max-width: ${innerWidth};
    margin: 0 auto;
    padding-top: 45px;
    padding-left: 25px;
    padding-right: 25px;
    @media ${screen.small} {
      padding-left: 40px;
      padding-right: 40px;
      padding-top: 90px;
    }
    @media ${screen.medium} {
      padding-left: 50px;
      padding-right: 50px;
    }
    @media ${screen.xLarge} {
      padding-left: 0;
      padding-right: 0;
    }

    &__heading {
      color: ${navyBlue};
      font-family: ${aku};
      font-size: 2.1rem;
      @media ${screen.small} {
        font-size: 2.7rem;
      }

      svg {
        width: 14px;
        margin-right: 8px;
        vertical-align: middle;
        @media ${screen.small} {
          width: 19px;
          margin-right: 14px;
        }
        @media ${screen.medium} {
          width: 24px;
        }

        .body {
          fill: ${navyBlue};
          transition: ${transHover};
        }
      }

      .active {
        .body {
          fill: ${mainRed};
        }
      }
    }

    &__subheading {
      color: ${navyBlue};
      font-family: ${gotham};
      font-size: 1rem;
      margin-top: 10px;
      @media ${screen.small} {
        font-size: 1.1rem;
      }
    }

    &__video-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 10px;
      @media ${screen.small} {
        margin-top: 30px;
      }
      .video-item {
        height: 200px;
        overflow: hidden;
        position: relative;
        margin: 8px 0;
        width: 100%;
        @media ${screen.small} {
          width: 49%;
          height: 230px;
        }
        @media ${screen.medium} {
          width: 32.5%;
          height: 240px;
        }
        @media ${screen.large} {
          height: 270px;
        }
        @media ${screen.xLarge} {
          margin: 10px 0;
          height: 335px;
        }

        &__texts {
          cursor: pointer;
          position: absolute;
          bottom: 0;
          top: 0;
          left: 0;
          right: 0;
          transition: ${transHover};
          padding-top: 20px;
          padding-left: 20px;
          @media ${screen.withCursor} {
            &:hover {
              background: rgba(200, 29, 41, 0.9);
            }
          }

          .video-title {
            color: ${mainWhite};
            font-family: ${gotham};
            font-size: 1.2rem;
            position: relative;
            max-width: 440px;
            padding-right: 20px;
            @media ${screen.small} {
              font-size: 1.3rem;
            }
            @media ${screen.xLarge} {
              padding-right: 0;
            }
            &::after {
              content: "";
              display: block;
              width: 45px;
              height: 2px;
              margin: 14px 0;
              background: ${mainWhite};
              @media ${screen.small} {
                margin: 18px 0;
                height: 3px;
              }
            }
          }

          .video-subtitle {
            color: ${mainWhite};
            font-family: ${gotham};
            font-size: 0.92rem;
            display: inline-block;
            margin-right: 5px;
            @media ${screen.small} {
              font-size: 1rem;
            }

            &:last-child {
              .video-subtitle--comma {
                display: none;
              }
            }
          }
        }

        &__video {
          width: 100% !important;
          height: 100% !important;
          pointer-events: none;
        }
        .react-player__play-icon,
        .react-player__shadow {
          display: none !important;
        }
      }

      &::after {
        content: "";
        overflow: hidden;
        position: relative;
        visibility: hidden;
        width: 100%;
        @media ${screen.small} {
          margin: 20px 0;
          width: 49%;
        }
        @media ${screen.medium} {
          width: 32.5%;
        }
      }
    }

    &__projects-link {
      background: ${navyBlue};
      transition: ${transHover};
      display: table;
      padding: 16px 29px;
      color: ${mainWhite};
      font-family: ${gotham};
      font-size: 1.1rem;
      margin-top: 20px;
      @media ${screen.small} {
        margin-top: 18px;
      }
      @media ${screen.large} {
        margin-top: 27px;
      }
      &:hover {
        background: ${mainRed};
      }
    }
  }

  .clients-container {
    background-image: ${(props) =>
      `linear-gradient(90deg, ${props.bgColour[0].colour}, ${props.bgColour[1].colour}, ${props.bgColour[2].colour}, ${props.bgColour[3].colour})`};
    background-size: 360%;
    animation: ${moveBgColor} 8s infinite alternate;
    margin-top: 60px;
    padding-left: 25px;
    padding-right: 25px;
    position: relative;
    @media ${screen.small} {
      padding-left: 40px;
      padding-right: 40px;
      margin-top: 90px;
    }
    @media ${screen.medium} {
      padding-left: 50px;
      padding-right: 50px;
    }
    @media ${screen.xLarge} {
      padding-left: 0;
      padding-right: 0;
    }

    .clients-subcontainer {
      max-width: ${innerWidth};
      margin: 0 auto;
      padding-top: 60px;
      @media ${screen.small} {
        padding-top: 90px;
      }
    }

    &__heading {
      color: ${mainWhite};
      font-family: ${aku};
      font-size: 2.1rem;
      @media ${screen.small} {
        font-size: 2.7rem;
      }

      svg {
        width: 14px;
        margin-right: 8px;
        vertical-align: middle;
        @media ${screen.small} {
          width: 19px;
          margin-right: 14px;
        }
        @media ${screen.medium} {
          width: 24px;
        }

        .body {
          fill: ${mainWhite};
          transition: ${transHover};
        }
      }

      .active {
        .body {
          fill: ${mainRed};
        }
      }
    }

    &__subheading {
      color: ${mainWhite};
      font-family: ${gotham};
      font-size: 1rem;
      margin-top: 10px;
      max-width: 430px;
      @media ${screen.small} {
        font-size: 1.1rem;
      }
    }

    &__client-list {
      display: flex;
      flex-wrap: wrap;
      max-width: ${innerWidth};
      margin: 10px -25px 0 -25px;
      padding-bottom: 48px;
      @media ${screen.small} {
        padding-bottom: 60px;
      }
      @media ${screen.medium} {
        margin: 35px -46px 0 -46px;
        padding-bottom: 70px;
      }
      @media ${screen.large} {
        padding-bottom: 90px;
        max-width: calc(${innerWidth} + 144px);
        margin: 35px auto 0 auto;
      }

      .client-item {
        width: 33.33%;
        padding: 18px 26px;
        align-self: center;
        @media ${screen.small} {
          width: 25%;
          padding: 41px 46px;
        }
        @media ${screen.medium} {
          width: 20%;
        }
        @media ${screen.large} {
          width: 16.66%;
          padding: 30px 45px;
        }
        @media ${screen.xLarge} {
          width: 16.66%;
          padding: 41px 72px;
        }

        img {
          transition: ${transHover} !important;
          &:hover {
            filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.3));
          }
        }
      }

      div {
        outline: none;
      }
    }

    .prev-btn {
      display: none;
      @media ${screen.large} {
        display: block;
        cursor: pointer;
        transform: scale(-1);
        position: absolute;
        z-index: 5;
        top: 356px;
        left: 35px;
      }
      @media ${screen.medium} {
        left: 12px;
        top: 351px;
      }
      @media ${screen.large} {
        left: 28px;
        top: 322px;
      }
      @media ${screen.xLarge} {
        left: 35px;
        top: 356px;
      }

      svg {
        width: 26px;

        .body {
          fill: ${mainWhite};
          transition: ${transHover};
        }
      }

      &:hover .body {
        fill: ${mainRed};
      }
    }

    .next-btn {
      display: none;
      @media ${screen.large} {
        display: block;
        cursor: pointer;
        position: absolute;
        z-index: 5;
        top: 360px;
        right: 35px;
      }
      @media ${screen.medium} {
        right: 12px;
        top: 355px;
      }
      @media ${screen.large} {
        right: 28px;
        top: 326px;
      }
      @media ${screen.xLarge} {
        right: 35px;
        top: 360px;
      }
      svg {
        width: 22px;
        @media ${screen.small} {
          width: 26px;
        }

        .body {
          fill: ${mainWhite};
          transition: ${transHover};
        }
      }

      &:hover .body {
        fill: ${mainRed};
      }
    }
  }

  .testimonials-container {
    max-width: ${innerWidth};
    margin: 0 auto;
    padding-top: 60px;
    padding-bottom: 120px;
    padding-left: 25px;
    padding-right: 25px;
    @media ${screen.small} {
      padding-left: 40px;
      padding-right: 40px;
      padding-top: 90px;
      padding-bottom: 100px;
    }
    @media ${screen.medium} {
      padding-left: 50px;
      padding-right: 50px;
    }
    @media ${screen.xLarge} {
      padding-left: 0;
      padding-right: 0;
    }

    &__heading {
      color: ${navyBlue};
      font-family: ${aku};
      font-size: 2.1rem;
      @media ${screen.small} {
        font-size: 2.7rem;
      }

      svg {
        width: 14px;
        margin-right: 8px;
        vertical-align: middle;
        @media ${screen.small} {
          width: 19px;
          margin-right: 14px;
        }
        @media ${screen.medium} {
          width: 24px;
        }

        .body {
          fill: ${navyBlue};
          transition: ${transHover};
        }
      }

      .active {
        .body {
          fill: ${mainRed};
        }
      }
    }

    &__subheading {
      color: ${navyBlue};
      font-family: ${gotham};
      font-size: 1rem;
      margin-top: 10px;
      @media ${screen.small} {
        font-size: 1.1rem;
      }
    }

    &__slider {
      background-image: linear-gradient(
        45deg,
        ${navyBlue},
        ${mainBlue},
        ${lightBlue}
      );
      position: relative;
      margin-top: 35px;
      box-shadow: 0 5px 7px -1px rgba(0, 0, 0, 0.4);
      @media ${screen.small} {
        margin-top: 45px;
      }

      .testimonial {
        display: flex !important;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding-top: 45px;
        padding-bottom: 25px;
        padding-left: 25px;
        padding-right: 25px;
        @media ${screen.small} {
          flex-direction: row;
          padding-top: 80px;
          padding-bottom: 30px;
          padding-left: 60px;
          padding-right: 60px;
        }
        @media ${screen.medium} {
          padding-left: 120px;
          padding-right: 120px;
        }
        @media ${screen.large} {
          padding-left: 60px;
          padding-right: 60px;
        }
        @media ${screen.xLarge} {
          padding-left: 0;
          padding-right: 0;
        }

        .column1 {
          display: flex;
          max-width: 390px;
          width: 100%;
          flex-direction: row;
          order: 1;
          @media ${screen.small} {
            max-width: 200px;
            margin-right: 50px;
            order: 0;
            flex-direction: column;
          }
          @media ${screen.medium} {
            max-width: 220px;
          }
          @media ${screen.large} {
            flex-direction: row;
            max-width: 400px;
            margin-right: 0;
          }
          @media ${screen.xLarge} {
            max-width: 400px;
            margin-right: 50px;
          }
        }

        .column2 {
          max-width: 750px;
          width: 100%;

          .msg {
            color: ${mainWhite};
            font-size: 1.05rem;
            font-family: ${gotham};
            @media ${screen.small} {
              font-size: 1.2rem;
            }
          }

          .link {
            color: ${mainWhite};
            font-family: ${gothamBold};
            font-size: 1rem;
            display: block;
            margin-top: 10px;
            margin-bottom: 25px;
            transition: ${transHover};
            @media ${screen.small} {
              margin-top: 15px;
              margin-bottom: 0;
            }

            &:hover {
              color: ${mainRed};
            }
            &:hover svg {
              .body {
                fill: ${mainRed};
              }
            }

            svg {
              width: 10px;
              vertical-align: middle;
              margin-left: 6px;

              .body {
                transition: ${transHover};
                fill: ${mainWhite};
              }
            }
          }
        }

        &__person-img {
          width: 100%;
          max-width: 166px;
          min-width: 166px;
          padding-right: 10px;
          @media ${screen.small} {
            padding-right: 0;
            max-width: 180px;
          }
        }

        &__person-info {
          margin-left: 8px;
          padding-right: 10px;
          @media ${screen.large} {
            align-self: center;
            margin-left: 30px;
            padding-right: 25px;
          }

          .heading {
            color: ${mainWhite};
            font-family: ${aku};
            font-size: 1.2rem;
            margin-bottom: 5px;
            font-weight: 100;
            @media ${screen.small} {
              font-size: 2.1rem;
              margin-bottom: 15px;
              margin-top: 10px;
            }
            @media ${screen.large} {
              font-size: 1.3rem;
              margin-top: 0;
              margin-bottom: 30px;
            }
          }
          .fullname {
            color: ${mainWhite};
            font-family: ${gothamBold};
            font-size: 1.1rem;
            @media ${screen.small} {
              font-size: 1.3rem;
            }
          }

          .occupation {
            color: ${mainWhite};
            font-family: ${gotham};
            font-size: 0.8rem;
            @media ${screen.small} {
              font-size: 1rem;
            }
          }

          .company {
            color: ${mainWhite};
            font-family: ${gotham};
            font-size: 0.8rem;
            @media ${screen.small} {
              font-size: 1.1rem;
            }
          }
        }
      }

      .slick-dots {
        bottom: -70px;
        padding-bottom: 20px;

        li {
          margin: 0 7px;

          button {
            border: 2px solid ${mainRed};
            border-radius: 50%;
            width: 15px;
            height: 15px;

            &::before {
              border-radius: 50%;
              opacity: 1 !important;
              color: ${mainWhite};
              width: 15px;
              height: 15px;
            }
          }
        }

        .slick-active {
          button {
            background-color: ${mainWhite};

            &::before {
              background-color: ${mainRed};

              color: ${mainRed};
            }
          }
        }
      }

      .prev-btn {
        display: none;
        @media ${screen.small} {
          display: flex;
          background: ${mainWhite};
          border-radius: 50%;
          box-shadow: 0 -5px 7px -1px rgba(0, 0, 0, 0.4);
          cursor: pointer;
          width: 60px;
          height: 60px;
          transform: scale(-1);
          position: absolute;
          z-index: 5;
          top: 45%;
          left: -30px;
          justify-content: center;
          align-items: center;
        }
        svg {
          width: 23px;
          margin-left: 5px;
        }
      }

      .next-btn {
        display: none;
        @media ${screen.small} {
          display: flex;
          background: ${mainWhite};
          border-radius: 50%;
          box-shadow: 0 5px 7px -1px rgba(0, 0, 0, 0.4);
          cursor: pointer;
          width: 60px;
          height: 60px;
          justify-content: center;
          align-items: center;
          position: absolute;
          z-index: 5;
          top: 45%;
          right: -30px;
          svg {
            width: 23px;
            margin-left: 5px;
          }
        }
      }
    }
  }
`;

const IndexPage = ({ data }) => {
  // basic hack to trigger the video modal component to watch location hash changes
  const [triggerLightboxComponent, setTriggerLightboxComponent] = useState(
    false
  );

  // checker if user on mobile screen size device
  const [isMobile, setMobile] = useState(
    typeof window !== "undefined" && window.innerWidth < 650
  );

  const backgroundColour =
    data.content.data.background_colour.length === 4
      ? data.content.data.background_colour
      : [
          { colour: navyBlue },
          { colour: navyBlue },
          { colour: navyBlue },
          { colour: navyBlue },
        ];

  // Prismic content
  const bannerHeading = data.content.data.heading;
  const bannerSubheading = data.content.data.subheading;
  const bannerVideoUrl =
    data.content.data.background_video.document?.data?.video_url;
  const bannerVideoYear = data.content.data.background_video.document?.data?.date
    ? data.content.data.background_video.document?.data?.date.slice(0, 4)
    : new Date().getFullYear();

  const titleTag = data.content.data.title_tag
    ? data.content.data.title_tag
    : `TEN ALPHAS: Moving Content`;
  const metaDescription = data.content.data.meta_description
    ? data.content.data.meta_description
    : `TEN ALPHAS: Moving Content`;
  const clientsSubheading = data.content.data.subheading1;
  const clientList = data.content.data.friend_list;
  const latestWorkList = isMobile
    ? data.content.data.list.slice(0, 3)
    : data.content.data.list;
  const testimonialList = data.content.data.list1;
  const contentList1 = data.content.data.list_1;
  const contentList2 = data.content.data.list_2;
  const contentList3 = data.content.data.list_3;

  // clients carousel settings
  const clientSettings = {
    dots: false,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 6,
    adaptiveHeight: false,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1278,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
        },
      },
      {
        breakpoint: 1160,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 413,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
    ],
  };

  // testimonials carousel settings
  const testimonialSettings = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: false,
  };

  // variable to be used for the "next method" of testimonial carousel
  let sliderTestimonialNext;
  // variable to be used for the "previous method" of testimonial carousel
  let sliderTestimonialPrev;
  // variable to be used for the "next method" of client carusel
  let sliderClientNext;
  // variable to be used for the "previous method" of client carusel
  let sliderClientPrev;

  // open video modal
  const viewVideo = (videoId) => {
    window.history.replaceState(null, null, `#${videoId}`);
    setTriggerLightboxComponent(!triggerLightboxComponent);
  };

  // resize listener
  useEffect(() => {
    const CheckBrowserWidth = () => {
      if (window.innerWidth > 650) {
        setMobile(false);
      } else {
        setMobile(true);
      }
    };
    window.addEventListener("resize", CheckBrowserWidth);

    return () => {
      window.removeEventListener("resize", CheckBrowserWidth);
    };
  }, [isMobile]);

  let firstClientX;
  let firstClientY;
  let clientX;
  let clientY;

  // listener for touch event for preventing to scroll up/down when swiping the carousel.
  useEffect(() => {
    window.addEventListener("touchstart", touchStart);
    window.addEventListener("touchmove", preventTouch, { passive: false });

    return () => {
      window.removeEventListener("touchstart", touchStart);
      window.removeEventListener("touchmove", preventTouch, { passive: false });
    };
  });

  const touchStart = (e) => {
    firstClientX = e.touches[0].clientX;
    firstClientY = e.touches[0].clientY;
  };

  const preventTouch = (e) => {
    const minValue = 5; // threshold

    clientX = e.touches[0].clientX - firstClientX;
    clientY = e.touches[0].clientY - firstClientY;

    // vertical scrolling does not work when you start swiping horizontally.
    if (Math.abs(clientX) > minValue) {
      e.preventDefault();
      e.returnValue = false;
      return false;
    }
  };

  const isBrowser = typeof window !== "undefined"

  if (!data || !isBrowser) return null

  return (
    <Layout>
      <SEO title={titleTag} description={metaDescription} image={logo} />
      <Wrapper bgColour={backgroundColour}>
        <div className="hero-container">
          <span className="hero-container__play-icon">
            <IoIosPlay onClick={() => viewVideo(getVimeoId(bannerVideoUrl))} />
          </span>
          <ReactPlayer
            className="hero-container__video"
            url={bannerVideoUrl}
            loop={true}
            playing={true}
            muted
            config={{
              vimeo: {
                fullscreen: 0,
              },
            }}
          />
          <div className="hero-container__image">
            <ReactPlayer
              className="thumbnail"
              url={bannerVideoUrl}
              light={`https://vumbnail.com/${getVimeoId(bannerVideoUrl)}.jpg`}
            />
          </div>

          <div className="hero-container__overlay">
            <div className="texts">
              <h1 className="texts__site-title">
                {bannerHeading}
                <span className="texts__site-title--subtitle">
                  {bannerSubheading}
                </span>
              </h1>
            </div>
            <h2 className="video-title">
              TA SHOWREEL
              <span className="video-title--year">{bannerVideoYear}</span>
            </h2>
          </div>
        </div>

        <div id="services" className="services-container">
          <h2 className="services-container__heading">
            <Spy
              to="services"
              activeClass="active"
              spy={true}
              smooth={true}
              offset={-150}
            >
              <Arrow />
            </Spy>
            What type of content are you looking for?
          </h2>
          <div className="services-container__service-lists">
            <ul className="list">
              {contentList1.map(
                (content, i) =>
                  content.service_or_sector && (
                    <li key={i}>
                      <Link
                        to={`/${toSlug(
                          content.service_or_sector.document?.data?.title.text
                        )}`}
                      >
                        {content.service_or_sector.document?.data?.title.text}
                      </Link>
                    </li>
                  )
              )}
              <li>
                <Link to="/design">Design</Link>
              </li>
            </ul>
            <ul className="list">
              {contentList2.map(
                (content, i) =>
                  content.service_or_sector && (
                    <li key={i}>
                      <Link
                        to={`/${toSlug(
                          content.service_or_sector.document?.data?.title.text
                        )}`}
                      >
                        {content.service_or_sector.document?.data?.title.text}
                      </Link>
                    </li>
                  )
              )}
              <li>
                <Link to="/infographics">Infographics</Link>
              </li>
            </ul>
            <ul className="list">
              {contentList3.map(
                (content, i) =>
                  content.service_or_sector && (
                    <li key={i}>
                      <Link
                        to={`/${toSlug(
                          content.service_or_sector.document?.data?.title.text
                        )}`}
                      >
                        {content.service_or_sector.document?.data?.title.text}
                      </Link>
                    </li>
                  )
              )}
              <li>
                <Link to="/photography">Photography</Link>
              </li>
            </ul>
          </div>
        </div>

        <section className="latest-work-container">
          <h2 className="latest-work-container__heading">
            <Spy
              to="/"
              activeClass="active"
              spy={true}
              smooth={true}
              offset={-150}
            >
              <Arrow />
            </Spy>
            Our Latest Work
          </h2>
          <p className="latest-work-container__subheading">
            Watch our most recent projects below.
          </p>
          <ul className="latest-work-container__video-list">
            {latestWorkList.map((item, i) => (
              <li
                className="video-item"
                onClick={(e) => {
                  e.preventDefault();
                  viewVideo(getVimeoId(item.project.document?.data?.video_url));
                }}
                key={i}
              >
                <div className="video-item__texts">
                  <h2 className="video-title">
                    {item.project.document?.data?.title.text}
                  </h2>
                  {item.project.document?.data?.categories.map((cat, i) => (
                    <p className="video-subtitle" key={i}>
                      {cat.category.document &&
                        cat.category.document?.data.title.text}
                      <span className="video-subtitle--comma">,</span>
                    </p>
                  ))}
                </div>
                <ReactPlayer
                  className="video-item__video"
                  url={item.project.document?.data?.video_url}
                  light={`https://vumbnail.com/${getVimeoId(
                    item.project.document?.data?.video_url
                  )}.jpg`}
                />
              </li>
            ))}
          </ul>

          <Link
            className="latest-work-container__projects-link"
            to="/portfolio"
          >
            See More Projects
          </Link>
        </section>

        <section id="friends" className="clients-container">
          <div className="clients-subcontainer">
            <h2 className="clients-container__heading">
              <Spy
                to="friends"
                activeClass="active"
                spy={true}
                smooth={true}
                offset={-150}
              >
                <Arrow />
              </Spy>
              FRIENDS
            </h2>
            <p className="clients-container__subheading">{clientsSubheading}</p>
          </div>
          <span className="prev-btn" onClick={() => sliderClientPrev()}>
            <Arrow />
          </span>
          <span className="next-btn" onClick={() => sliderClientNext()}>
            <Arrow />
          </span>
          <Slider
            ref={(c) => {
              sliderClientNext = c && c.slickNext;
              sliderClientPrev = c && c.slickPrev;
            }}
            {...clientSettings}
            className="clients-container__client-list"
          >
            {clientList.map((client, i) => (
              <div
                className="client-item"
                key={i}
                onClick={() =>
                  client.project &&
                  viewVideo(getVimeoId(client.project.document?.data?.video_url))
                }
              >
                {client.logo ? (
                  <GatsbyImage
                    style={{ cursor: client.project ? `pointer` : `auto` }}
                    image={client.logo.gatsbyImageData}
                    alt="Client Logo"
                  />
                ) : (
                  <img src="/x" alt="not found" />
                )}
              </div>
            ))}
          </Slider>
        </section>

        <section id="testimonials" className="testimonials-container">
          <h2 className="testimonials-container__heading">
            <Spy
              to="testimonials"
              activeClass="active"
              spy={true}
              smooth={true}
              offset={-150}
            >
              <Arrow />
            </Spy>
            TESTIMONIALS
          </h2>
          <p className="testimonials-container__subheading">
            Our customers love what we do.
          </p>
          <div className="testimonials-container__slider">
            <span className="prev-btn" onClick={() => sliderTestimonialPrev()}>
              <Arrow />
            </span>
            <span className="next-btn" onClick={() => sliderTestimonialNext()}>
              <Arrow />
            </span>
            <Slider
              ref={(c) => {
                sliderTestimonialNext = c && c.slickNext;
                sliderTestimonialPrev = c && c.slickPrev;
              }}
              {...testimonialSettings}
            >
              {testimonialList.map((item, i) => (
                <React.Fragment key={i}>
                  <div className="testimonial">
                    <div className="column1">
                      <div className="testimonial__person-img">
                        {item.testimonial.document?.data?.image
                          .gatsbyImageData && (
                          <GatsbyImage
                            image={
                              item.testimonial.document?.data?.image
                                .gatsbyImageData
                            }
                            alt="Featured"
                          />
                        )}
                      </div>
                      <div className="testimonial__person-info">
                        <h5 className="heading">SHARED BY</h5>
                        <h6 className="fullname">
                          {item.testimonial.document?.data?.full_name.text}
                        </h6>
                        <p className="occupation">
                          {item.testimonial.document?.data?.title_occupation}
                        </p>
                        <p className="company">
                          {item.testimonial.document?.data?.company}
                        </p>
                      </div>
                    </div>

                    <div className="column2">
                      <p className="msg">
                        {reduceWords(
                          item.testimonial.document?.data?.message.text,
                          38
                        )}
                      </p>
                      <Link
                        className="link link--desktop"
                        to="/testimonials"
                        state={{
                          testimonialId: item.testimonial.document?.id,
                        }}
                      >
                        Read more <Arrow />
                      </Link>
                    </div>
                  </div>
                </React.Fragment>
              ))}
            </Slider>
          </div>
        </section>

        <VideoModal triggerLightboxComponent={triggerLightboxComponent} />
        <NewsletterPopup isHome={true} />
      </Wrapper>
    </Layout>
  );
};

export default IndexPage;

export const dataQuery = graphql`
  {
    content: prismicHomePage {
      data {
        heading
        subheading
        background_video {
          document {
            ... on PrismicProject {
              data {
                title {
                  text
                }
                date
                video_url
                credits {
                  description
                }
              }
            }
          }
        }
        subheading1
        title_tag
        meta_description
        background_colour {
          colour
        }
        friend_list {
          project {
            document {
              ... on PrismicProject {
                data {
                  video_url
                }
              }
            }
          }
          logo {
            alt
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        list {
          project {
            document {
              ... on PrismicProject {
                data {
                  title {
                    text
                  }
                  categories {
                    category {
                      document {
                        ... on PrismicCategory {
                          data {
                            title {
                              text
                            }
                          }
                        }
                      }
                    }
                  }
                  date
                  video_url
                  credits {
                    description
                  }
                }
              }
            }
          }
        }
        list1 {
          testimonial {
            document {
              ... on PrismicTestimonial {
                id
                data {
                  full_name {
                    text
                  }
                  title_occupation
                  company
                  message {
                    text
                  }
                  image {
                    alt

                    gatsbyImageData(layout: FULL_WIDTH)
                  }
                }
              }
            }
          }
        }
        list_1 {
          service_or_sector {
            document {
              ... on PrismicSector {
                data {
                  title {
                    text
                  }
                }
              }
              ... on PrismicService {
                data {
                  title {
                    text
                  }
                }
              }
            }
          }
        }
        list_2 {
          service_or_sector {
            document {
              ... on PrismicSector {
                data {
                  title {
                    text
                  }
                }
              }
              ... on PrismicService {
                data {
                  title {
                    text
                  }
                }
              }
            }
          }
        }
        list_3 {
          service_or_sector {
            document {
              ... on PrismicSector {
                data {
                  title {
                    text
                  }
                }
              }
              ... on PrismicService {
                data {
                  title {
                    text
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
